import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box'

function TabPanel(props){
    const { children, value, index, ...other } = props;

    return(
        <div 
            role="tabpanel"
            hidden={value !== index}
            id={`program-tabpanel-${index}`}
            aria-labelledby={`program-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}

        </div>
    )
}

TabPanel.propTypes={
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `program-tab-${index}`,
        'aria-controls': `program-tabpanel-${index}`,
    };
}

const PartnerDialog = (props) => {

    const { open, partner, close } = props;

    const [value, setValue] = React.useState(0);

    const handleClose = () => {
        close();
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
    }

    return(
        <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth >
            <DialogTitle disableTypography>
                <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                        {partner.ImageURL && <img src={partner.ImageURL} style={{ width: '200px' }} />}
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='h4'>
                            {partner.Insitution}
                        </Typography>
                    </Grid>                    
                </Grid>
                {/* <Grid container>
                    <Grid item xs={10}>
                        <Typography variant='h4'>
                            {partner.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'right' }}>
                        <img src={partner.logo} style={{ width: '100px' }} />
                    </Grid>
                </Grid> */}
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} style={{ padding: '10px', textAlign: 'center' }}>
                        {partner.InsURL && <Typography variant='h6'>
                            <b>Institution URL:</b> <a href={partner.InsURL} target="_blank">{partner.InsURL}</a>
                        </Typography>}
                        {partner.InsPathway && <Typography variant="h6">
                        <b>Available Pathways:</b> {partner.InsPathway}
                        </Typography>}
                        {partner.InsFinancialIncentive && <Typography variant='h6'>
                        <b>Financial Incentive:</b> {partner.InsFinancialIncentive}
                        </Typography>}
                        {partner.InsGPA && <Typography variant='h6'>
                            <b>Instituion GPA:</b> {partner.InsGPA}
                        </Typography>}
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        {partner.MDCURL && <Button variant="contained" target="_blank" href={partner.MDCURL} color='primary'>Learn More</Button>}
                    </Grid>
                    {partner.programs && partner.programs.length > 0 && 
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <AppBar position="static" color="default">
                                <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleTabChange} aria-label="program tabs">
                                    {partner.programs.map((p, i) => {
                                        return (
                                            <Tab label={p.ProgramName} {...a11yProps(i)} />
                                        )
                                    })}
                                </Tabs>
                                {partner.programs.map((p, i) => {
                                    return (
                                        <TabPanel value={value} index={i}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1">
                                                        Program Pathway: {p.ProPathway}
                                                    </Typography>
                                                    {p.ProFinancialIncentive && <Typography variant="body1">
                                                        Financial Incentive: {p.ProFinancialIncentive}
                                                    </Typography>}
                                                </Grid>
                                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                    <Button variant="contained" href={p.ProURL} target="_blank">Learn More</Button>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    )
                                })}
                            </AppBar>
                        </Grid>
                    }
                </Grid>
                {/* <Grid container>
                    <Grid item xs={6}>
                        <Typography variant='h6'>
                            Instiution Address
                        </Typography>
                        <Typography variant='body1'>
                            {partner.address}
                        </Typography>
                        <Typography variant='body1'>
                            {partner.city}, {partner.state} {partner.postalCode}
                        </Typography>
                        <Typography variant='body1'>
                            P: {partner.phone}
                        </Typography>
                        <Typography variant='body1'>
                            F: {partner.fax}
                        </Typography>
                        <Typography variant='body1'>
                            <a href={partner.websiteUrl} target="_blank">{partner.websiteUrl}</a>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='h6'>
                            Administrative Contact
                        </Typography>
                        <Typography variant='body1'>
                            {partner.contactNameDept}
                        </Typography>
                        <Typography variant='body1'>
                            P: {partner.contactPhone}
                        </Typography>
                        {partner.contactEmail && (
                        <Typography variant='body1'>
                            <a href={`mailto:${partner.contactEmail}`}>{partner.contactEmail}</a>
                        </Typography>)}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Typography variant='h6'>
                            Admission Requirements:
                        </Typography>
                        <Typography variant='body1'>
                            {partner.admissionRequirements}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Typography variant='h6'>
                            Scholarships:
                        </Typography>
                        <Typography variant='body1'>
                            {partner.scholarships}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Typography variant='h6'>
                            Additional Info:
                        </Typography>
                        <Typography variant='body1'>
                            {partner.additionalInfo}
                        </Typography>
                    </Grid>
                </Grid> */}
            </DialogContent>
        </Dialog>
    )

}

export default PartnerDialog;