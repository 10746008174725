import React from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
  ZoomableGroup
} from "react-simple-maps";
import Tooltip from '@material-ui/core/Tooltip';
import { geoPath } from 'd3-geo';
import { geoTimes } from 'd3-geo-projection';

import allStates from "./data/allstates.json";
import gadm36USA from './data/gadm36_USA.json';
//import partners from './data/transferAgreements.json';
//import partners from './data/newData.json';
//import partners from './data/recordTest.json';
import partners from './data/newData-20210811.json';

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
//const geoUrl = gadm36USA;

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21]
};

const MapChart = ({ setToolCon, setThisPartner, setOpenDialog, stateClick }) => {


  const [center, setCenter] = React.useState([0,0]);
  const [zoom, setZoom] = React.useState(1);
  const [mapWidth, setMapWidth] = React.useState(1000);
  const [mapHeight, setMapHeight] = React.useState(500);
  const [circleR, setCircleR] = React.useState(2);

  let projection = () => {
    return geoTimes().translate([800 /2, 450 /2]).scale(160);
  }

  const handleClick = geo => () => {
    let path = geoPath().projection(projection());
    let centroid = projection().invert(path.centroid(geo));
    let bounds = path.bounds(geo);
    let dx = bounds[1][0] - bounds[0][0];
    let dy = bounds[1][1] - bounds[0][1];
    let newZoom = 0.9/Math.max(dx / (mapWidth/7), dy / (mapHeight/7));
    setZoom(newZoom);
    setCenter(centroid);
    setCircleR(.5);
  }

  const handleMove = info => {
    if(info.dragging.deltaY){
      let x = info.k
      switch(true){
        case (x < 3):
          setCircleR(2);
          break;
        case (x < 5):
          setCircleR(1);
          break;
        case (x < 8):
          setCircleR(.5);
          break;
      }
    }
  }

  return (
    <ComposableMap projection="geoAlbersUsa" width={mapWidth} height={mapHeight} onScroll={()=>console.log(zoom)}>
      <ZoomableGroup center={center} zoom={zoom} disablePanning={true} onMove={handleMove}>
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <>
            {geographies.map(geo => (
              <Geography
                key={geo.rsmKey}
                stroke="#FFF"
                geography={geo}
                fill="#DDD"
                onClick={handleClick(geo)}
                style={{
                  default: {
                    outline: 'none'
                  },
                  hover: {
                    outline: 'none',
                    fill: '#094578',
                    fillOpacity: '80%'
                  },
                  pressed: {
                    outline: 'none'
                  }
                }}
              />
            ))}
            {geographies.map(geo => {
              const centroid = geoCentroid(geo);
              const cur = allStates.find(s => s.val === geo.id);
              return (
                <g key={geo.rsmKey + "-name"}>
                  {cur &&
                    centroid[0] > -160 &&
                    centroid[0] < -67 &&
                    (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                      <Marker coordinates={centroid}>
                        <text y="2" fontSize={14} textAnchor="middle">
                          {cur.id}
                        </text>
                      </Marker>
                    ) : (
                      <Annotation
                        subject={centroid}
                        dx={offsets[cur.id][0]}
                        dy={offsets[cur.id][1]}
                      >
                        <text x={4} fontSize={14} alignmentBaseline="middle">
                          {cur.id}
                        </text>
                      </Annotation>
                    ))}
                </g>
              );
            })}
          </>
        )}
      </Geographies>

      {partners.map((p) => {
        
        return(
          <Marker
            key={p.id}
            data-tip=''
            coordinates={p.coordinates}
            style={{
                default: { fill: "#FF5722" },
                hover: { fill: "#094578" },
                pressed: { fill: "#FF5722" },
            }}
            onClick={(event)=>{
                setThisPartner(p);
                setOpenDialog(true);
            }}
            onMouseEnter={() => {
                setToolCon(p.Insitution);
            }}
            onMouseLeave={() => {
                setToolCon('');
            }}
            
            >
                <circle
                    cx={0}
                    cy={0}
                    r={circleR}
                    style={{
                        /* stroke: "#FF5722", */
                        /* strokeWidth: {circleR}/2, */
                        opacity: 0.9,
                    }}
                />
        </Marker>
      )}
      )}
      </ZoomableGroup>
    </ComposableMap>
  );
};

export default MapChart;