import React from "react";
import { geoCentroid } from "d3-geo";
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Marker,
    Annotation
} from "react-simple-maps";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { geoPath } from 'd3-geo';
import { geoTimes } from 'd3-geo-projection';

import partners from '../data/newData-20210811.json';
import azJson from '../data/az.json';

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json"
//let geoUrl = azJson;


const StateDialog = ({ open, close, selectedGeo }) => {


    const handleClose = () => {
        close();
    }

    const [center, setCenter] = React.useState([0,0]);
    const [zoom, setZoom] = React.useState(1);

    let projection = () => {
        return geoTimes().translate([800 /2, 450 /2 ]).scale(160);
    }

    React.useEffect(() => {
        console.log(selectedGeo);
        if(selectedGeo && open){
            let path = geoPath().projection(projection());
            let centroid = projection().invert(path.centroid(selectedGeo));
            setCenter(centroid);
            setZoom(5)
        } else {
            setCenter([0,0]);
            setZoom(1);
        }
    }, [selectedGeo]);



    return(
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
            <DialogTitle>
                {selectedGeo && selectedGeo.properties.name}
            </DialogTitle>
            <DialogContent>
                <ComposableMap 
                    projection='geoAlbersUsa'
                    >
                        <ZoomableGroup center={center} zoom={zoom}>
                    {selectedGeo && 
                    <Geographies geography={geoUrl} >
                        {({ geographies }) => geographies.map(geo => {
                            if(geo.id === selectedGeo.id){
                                //console.log(geo);
                                return(
                                <Geography 
                                    scale={3500}
                                    key={geo.rsmKey} 
                                    geography={geo} 
                                    stroke="#FFF"
                                    fill="#DDD"
                                    />
                                )
                            }
                        })}

                    </Geographies>}
                    </ZoomableGroup>
                    {/* partners.map((p) => {

                        return(
                            <Marker
                                key={p.id}
                                coordinates={p.coordinates}
                            >
                                <circle 
                                    cx={0}
                                    cy={0}
                                    r={2}
                                    style={{
                                        stroke: '#FF5722',
                                        strokeWidth: 1,
                                        opacity: 0.9,
                                    }}
                                />
                            </Marker>
                        )

                    }) */}
                </ComposableMap>

            </DialogContent>
        </Dialog>
    )

}

export default StateDialog;