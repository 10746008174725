import React from 'react';
import logo from './logo.svg';
import './App.css';
import MapChart from './MapChart.js'
import ReactTooltip from 'react-tooltip';
import PartnerDialog from './PartnerDialog';
import StateDialog from './StateDialog';

function App() {

  const [toolCon, setToolCon] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [thisPartner, setThisPartner] = React.useState({});
  const [openState, setOpenState] = React.useState(false);
  const [selectedState, setSelectedState] = React.useState(null);

  const closeDialog = () => {
    setOpenDialog(false);
    setThisPartner({});
  }

  const handleStateClick = (geo) => {
    setSelectedState(geo);
    setOpenState(true);
  }

  const handleStateClose = () => {
    setSelectedState(null);
    setOpenState(false);
  }

  return (
    <div className="App">
        <MapChart setToolCon={setToolCon} setOpenDialog={setOpenDialog} setThisPartner={setThisPartner} stateClick={handleStateClick} />
        <ReactTooltip>{toolCon}</ReactTooltip>
        <PartnerDialog open={openDialog} close={closeDialog} partner={thisPartner} />
        <StateDialog open={openState} close={handleStateClose} selectedGeo={selectedState} />
    </div>
  );
}

export default App;
